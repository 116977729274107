import { FC } from 'react';
import styled from 'styled-components';
import { BorderFreeRounded } from '../pages/Report/styles/Wrappers';
import { MiniSpinner, Spinner } from '../shared/styles/Spinner';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;
const Shader = styled(Wrapper)`
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
`;

const FlexSpan = styled.span`
    display: flex;
`;

const FilesExistListElement = styled.span`
    margin-right: 8px;
`;

const CenteredErrorMessage = styled(BorderFreeRounded)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    padding: 2vw;
    text-align: center;
    align-items: center;
`;

const ReportBeingGeneratedMessage: FC<{ createdAt?: number | null }> = ({ createdAt }) => {
    const minutesThreshold = 10;
    const date = new Date();
    const timeUTC = date.getTime() + date.getTimezoneOffset() * 60000;
    const timeDiffMinutes = createdAt ? (timeUTC - createdAt) / (1000 * 60) : null;
    if (timeDiffMinutes && timeDiffMinutes < minutesThreshold) {
        return (
            <h3>
                We are creating your report. <br />
                It should be ready in less than {Math.floor(minutesThreshold - timeDiffMinutes - 1)} minute
                {minutesThreshold - timeDiffMinutes - 1 === 1 ? '' : 's'}
            </h3>
        );
    }
    return (
        <>
            <h3>
                Your report was not generated correcly, <br />
            </h3>
            <img style={{ height: '40px' }} src="/images/icons/satellite.svg" alt="" />
            <ul>
                This is typically because AIS data does not exist for this time periode, <br /> or something went wrong
                on our end.
            </ul>
            Send us an email at connect@vake.ai so we can look into it.
        </>
    );
};

export const PathfinderErrorHandler: FC<{ hasAIS?: boolean; hasImages?: boolean; createdAt?: number | null }> = ({
    hasAIS,
    hasImages,
    createdAt,
}) => {
    return (
        <Shader>
            <CenteredErrorMessage style={{ zIndex: 99 }}>
                <h3 style={{ marginBottom: '1vw' }}>Pathfinder Report status: </h3>
                <>
                    <FlexSpan>
                        <FilesExistListElement>Gathering AIS track</FilesExistListElement>
                        <CheckOrCrossSymbol check={hasAIS} />
                    </FlexSpan>
                    <FlexSpan>
                        <FilesExistListElement>Correlating satelitte images with AIS track</FilesExistListElement>
                        <CheckOrCrossSymbol check={hasImages} />
                    </FlexSpan>
                </>
                <div style={{ height: '2vw' }} />
                {hasAIS === false && hasImages === false ? (
                    <ReportBeingGeneratedMessage createdAt={createdAt} />
                ) : (
                    <>
                        <Spinner />
                        <h4>Loading report...</h4>
                    </>
                )}
            </CenteredErrorMessage>
        </Shader>
    );
};

export const ClearviewErrorHandler: FC = () => {
    return (
        <Shader>
            <CenteredErrorMessage>
                Your user does not have access to the VAKE tool for locating dark vessels.
                <div style={{ height: '10%', maxWidth: '40px', margin: '2%' }}>
                    <img width={'100%'} src="/images/icons/cross_white.svg" alt="no source" />
                </div>
                Contact VAKE for a trial.
            </CenteredErrorMessage>
        </Shader>
    );
};

export const CheckOrCrossSymbol: FC<{ check?: boolean }> = ({ check }) => {
    if (typeof check === 'undefined') {
        return <MiniSpinner size={20} />;
    } else if (check) {
        return <img src="/images/icons/simpleSymbols/check.svg" alt="check" />;
    } else {
        return <img src="/images/icons/simpleSymbols/x.svg" alt="check" />;
    }
};
